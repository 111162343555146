export default function videoPlay() {

      
    $(".js-video").on("click", function () {

        const $video = $(this).find("video");
        const $poster = $(this).find('.js-video-poster');
        const $play = $(this).find('.js-video-play');

        $(this).addClass("-playing");

        if ($(this).hasClass("-playing")) {

            $('video').each(function() {
                $(this).trigger("pause");
                $(this).removeClass("-playing");
            });    

            $video.trigger("play");
            $poster.fadeOut();
            $play.fadeOut();
        } else {
            $video.trigger("pause");
            $(this).removeClass("-playing");
        }
    });

    $('video').on('pause', function() {
        const $videoWrapper = $(this).closest('.js-video');
        $videoWrapper.find('.js-video-poster').fadeIn();
        $videoWrapper.find('.js-video-play').fadeIn();
        $videoWrapper.removeClass("-playing");
    });
    
}