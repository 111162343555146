import DonutChart from 'donut-chart-js';


export default function chart() {
    const pieCharts = document.querySelectorAll('.calculations-pie-chart');
    console.log(pieCharts);

    pieCharts.forEach(item => {
        const dataEl = item.querySelector('[data-chart-data]')
        const data = JSON.parse(dataEl.textContent);
        const canvas = item.querySelector('[data-chart-canvas]');

        new DonutChart(canvas, {
            data: data,
            holeSize: 0.8,
            animationSpeed: 0.7,
        });
    });
}
