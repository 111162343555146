(function ($) {
    $(document).ready(function () {

        const header = $('#site-header'),
            // siteMain = $('.site-main'),
            scrollClass = 'site-header--scrolled',
            hiddenClass = 'site-header--hidden';

        // let headerHeight = header.outerHeight() + 'px';
        // siteMain.css('margin-top', headerHeight);

        let didScroll;
        let lastScrollTop = 0;
        let delta = 5;
        let headerOuterHeight = $('header').outerHeight();

        $(document).ready(function () {
            setMenuScrolled();
        });

        $(window).scroll(function (event) {
            if (!header.hasClass('site-header--open')) {
                didScroll = true;
                setMenuScrolled();
            }
        });

        setInterval(function () {
            if (didScroll) {
                hasScrolled();
                didScroll = false;
            }
        }, 250);

        function hasScrolled() {
            var st = $(this).scrollTop();

            if (Math.abs(lastScrollTop - st) <= delta) return;

            if (st > lastScrollTop && st > headerOuterHeight) {
                header.addClass(hiddenClass);
            } else {
                if (st + $(window).height() < $(document).height()) {
                    header.removeClass(hiddenClass);
                }
            }

            lastScrollTop = st;
        }

        function setMenuScrolled() {
            if ($(this).scrollTop() > 0 && !header.hasClass(scrollClass)) {
                header.addClass(scrollClass);
            } else if ($(this).scrollTop() == 0 && header.hasClass(scrollClass)) {
                header.removeClass(scrollClass);
            }
        }

    });
})(window.jQuery);