import { PageFlip } from 'page-flip';
import '@fancyapps/fancybox';

export default function pageFlipMDA() {
    // 📌 Inicjalizacja standardowej wersji PageFlip
    const book_Norm = document.getElementById('book--Norm');
    const pageCounter_Norm = document.getElementById('page-counter--Norm');

    const pageFlip_Norm = new PageFlip(book_Norm, {
        width: 320,
        height: 480,
        showCover: true,
        maxShadowOpacity: 0.5,
        useMouseEvents: true,
        flippingTime: 800,
        startPage: 0,
    });

    pageFlip_Norm.loadFromHTML(document.querySelectorAll('.my-page--Norm'));

    document.querySelector('.swiper-button-next').addEventListener('click', () => {
        pageFlip_Norm.flipNext();
    });

    document.querySelector('.swiper-button-prev').addEventListener('click', () => {
        pageFlip_Norm.flipPrev();
    });

    const updatePageCounter_Norm = () => {
        const currentPage = pageFlip_Norm.getCurrentPageIndex() + 1;
        const totalPages = pageFlip_Norm.getPageCount();
        pageCounter_Norm.textContent = `Strona ${currentPage} z ${totalPages}`;
    };

    pageFlip_Norm.on('flip', updatePageCounter_Norm);
    updatePageCounter_Norm();

    // 📌 Lightbox (Fancybox) + PageFlip
    let pageFlip_Lightbox = null;

    document.querySelector('.open-lightbox').addEventListener('click', (event) => {
        event.preventDefault();

        // 📌 Pobieranie zawartości szablonu (HTML ukrytego w DOM)
        const template = document.querySelector('#pageflip-template').innerHTML;

        // 📌 Tworzenie kontenera dla lightboxa
        const lightboxContainer = document.createElement('div');
        lightboxContainer.classList.add('lightbox-content');
        lightboxContainer.innerHTML = template;
        document.body.appendChild(lightboxContainer);

        // 📌 Otwarcie Fancyboxa z dynamiczną zawartością
        $.fancybox.open({
            src: lightboxContainer,
            type: 'inline',
            touch: false,
            afterClose: function () {
                if (pageFlip_Lightbox) {
                    pageFlip_Lightbox.destroy();
                }
                document.body.removeChild(lightboxContainer);
            },
            afterShow: function () {
                const bookContainer = lightboxContainer.querySelector('#book');

                // 📌 Sprawdzenie czy bookContainer istnieje
                if (!bookContainer) {
                    console.error("Brak kontenera #book w Lightboxie!");
                    return;
                }

                setTimeout(() => {
                    adjustPageFlipSize(bookContainer);

                    pageFlip_Lightbox = new PageFlip(bookContainer, {
                        width: bookContainer.clientWidth,
                        height: bookContainer.clientHeight,
                        showCover: true,
                        maxShadowOpacity: 0.5,
                        flippingTime: 800,
                    });

                    pageFlip_Lightbox.loadFromHTML(bookContainer.querySelectorAll('.my-page'));

                    lightboxContainer.querySelector('.swiper-button-next').addEventListener('click', () => {
                        pageFlip_Lightbox.flipNext();
                    });

                    lightboxContainer.querySelector('.swiper-button-prev').addEventListener('click', () => {
                        pageFlip_Lightbox.flipPrev();
                    });

                    window.addEventListener('resize', () => adjustPageFlipSize(bookContainer));
                }, 100);
            },
        });
    });

    function adjustPageFlipSize(bookContainer) {
        const maxHeight = window.innerHeight * 0.85;
        const maxWidth = maxHeight * 0.7;

        bookContainer.style.width = `${maxWidth}px`;
        bookContainer.style.height = `${maxHeight}px`;

        if (pageFlip_Lightbox) {
            pageFlip_Lightbox.update();
        }
    }
}