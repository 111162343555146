export default function calculations() {
    $('[data-toggle-calculation]').click(function(event) {
        const target = $(this).attr('data-toggle-calculation');
        
        $(`[data-toggle-calculation]`).removeClass('salary-box__body--accent');
        $(this).addClass('salary-box__body--accent');

        $('[data-calculations]').removeClass('active');
        $(`[data-calculations="${target}"]`).addClass('active');
    });
}