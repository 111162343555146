export default function searchForm() {
    var $search = $("#js_header_search"),
        $searchForm = $("#js_header_search_form"),
        $searchInput = $("#js_header_search_input"),
        $searchResults = $("#js_header_search_results"),
        $searchSubmit = $("#js_header_search_submit"),
        searchActiveClass = "is-active";

    $searchSubmit.on("click", function () {
        $search.addClass(searchActiveClass);
        $searchInput.get(0).focus();
    });

    $searchInput.on("keyup", function (e) {

    });

    document.addEventListener("click", function (event) {
        if ($search.get(0) !== event.target && !$search.get(0).contains(event.target)) {
            $search.removeClass(searchActiveClass);
            $search.val("");
            $searchResults.removeClass("is-visible");
        }
    });
}