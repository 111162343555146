export default function menuMobileShow() {

    var $collapse = $('#collapse'),
        burger = $('#burger');

    burger.click(function () {
        $(this).toggleClass('burger-icon--open');

        if ($collapse.hasClass('nav-site--open')) {
            $collapse.addClass('nav-site--closed');
            // $('body').removeClass('no-scroll');
            // $('.header .button').addClass('btn--white');

            setTimeout(function () {
                $collapse.removeClass('nav-site--closed').removeClass('nav-site--open');
            }, 250);

        } else {
            $collapse.addClass('nav-site--opened');
            // $('body').addClass('no-scroll');
            // $('.header .button').removeClass('btn--white');

            setTimeout(function () {
                $collapse.removeClass('nav-site--opened').addClass('nav-site--open');
            }, 250);
        }
    });

}