import Swiper from 'swiper';
import { Navigation, Pagination, EffectFade, Autoplay } from 'swiper/modules';

Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

function afterSlider() {

    var swiper = new Swiper(".js-after-slider", {
        slidesPerView: 1.2,
        spaceBetween: 0,
        centeredSlides: true,
        direction: 'horizontal',
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev"
        },
        navigationDisabledClass: '-navigation-disabled'
    });

    swiper.on('slideChangeTransitionStart', function () {
        $('video').each(function () {
            $(this).trigger("pause");
            $(this).removeClass("-playing");
        });
    })
}

function heroSlider() {

    var swiper2 = new Swiper(".hero-slider", {
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        effect: 'fade',
        fadeEffect: {
            crossFade: true
        },
        loop: true,
        autoplay: {
            delay: 5000,
        },
        breakpoints: {
            768: {
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
            },
            0: {
                navigation: false,
                pagination: false,
            },
        },
        on: {
            init: function () {
                if (!this.params.navigation) {
                    document.querySelectorAll(".swiper-button-next, .swiper-button-prev").forEach(el => {
                        el.style.display = "none";
                    });
                }
            },
        },
    });
}


function articleSlider() {

    var swiper = new Swiper(".js-after-article-slider", {
        slidesPerView: 1.05,
        spaceBetween: 8,
        direction: 'horizontal',
        autoHeight: false,
        navigation: {
            nextEl: ".swiper-articles-button-next",
            prevEl: ".swiper-articles-button-prev"
        },
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: 16,
            }
        }
    });
}

function podcastSlider() {

    var swiper = new Swiper(".podcast-slider", {
        slidesPerView: 1.05,
        spaceBetween: 8,
        direction: 'horizontal',
        breakpoints: {
            768: {
                slidesPerView: 2.05,
                spaceBetween: 16,
                navigation: {
                    nextEl: ".podcast-slider-button-next",
                    prevEl: ".podcast-slider-button-prev"
                },
            },
            992: {
                slidesPerView: 3.05,
                spaceBetween: 16,
                navigation: {
                    nextEl: ".podcast-slider-button-next",
                    prevEl: ".podcast-slider-button-prev"
                },
            }
        }
    });
}

export {
    afterSlider,
    articleSlider,
    heroSlider,
    podcastSlider
}