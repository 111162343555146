export default function salaryForm() {
    if ($('#myJson').length) {
        (function () {
            const Search = {
                data: {
                    list: JSON.parse($('#myJson').html()),
                    active_work_position: null,
                    url: null
                },
                init: function () {
                    this.form = document.getElementById('salary_form');
                    this.work_position_input = document.getElementById('work_position');
                    this.voivodeship_input = document.getElementById('voivodeship');
                    this.salary_search_submit = document.getElementById('salary_search_submit');
                    this.work_position_suggestions = document.getElementById('work_position_suggestions');
                    this.work_position_field_error = document.getElementById('workPositionFieldError');

                    this.watchSalaryForm();
                    this.watchWorkPositon();
                    this.watchVoivodeship();
                    this.closeSuggestions();
                },
                closeSuggestions: function () {
                    document.onclick = (e) => {
                        if (e.target.id !== 'work_position_suggestions') {
                            this.work_position_suggestions.style.display = 'none';
                        }
                    };
                },
                generateUrl: function () {
                    if (!this.data.active_work_position) {
                        // this.salary_search_submit.disabled = true
                        
                        return
                    }
                    // this.salary_search_submit.disabled = false
                    
                    let url = '';
                    url += this.data.active_work_position.url;
                    
                    if (this.voivodeship_input.value != 'all' && this.voivodeship_input.value != '' && this.voivodeship_input.value != 'null' && this.voivodeship_input.value != null)
                    url += '?wojewodztwo=' + this.voivodeship_input.value;
                    
                    this.data.url = url;
                },
                watchVoivodeship: function () {
                    const ref = this;
                    $('#voivodeship').on('select2:select', function (e) {
                        ref.generateUrl()
                    });
                    $('#voivodeship').on('select2:clear', function (e) {
                        ref.generateUrl()
                    });
                    this.voivodeship_input.addEventListener('change', (e) => {
                        this.generateUrl()
                    })
                },
                watchSalaryForm: function () {
                    this.form.addEventListener('submit', (e) => {
                        e.preventDefault();
                        if(!this.data.active_work_position){
                            this.work_position_field_error.style.display = 'block';
                            return;
                        }
                        this.work_position_field_error.style.display = 'none';

                        if (!this.data.url)
                            return

                        window.location.href = this.data.url;
                    })
                },
                watchWorkPositon: function () {
                    this.work_position_input.addEventListener('input', (e) => {
                        this.voivodeship_input.innerHTML = "";
                        this.voivodeship_input.disabled = true;
                        this.work_position_field_error.style.display = 'none';
                        this.data.active_work_position = null;
                        this.addElement();
                        this.generateUrl();
                    })
                },
                setVoivodeshipOptions: function () {
                    this.voivodeship_input.disabled = false;
                    if (!this.data.active_work_position || !this.data.active_work_position.kryteria.wojewodztwo) {
                        this.voivodeship_input.disabled = true;
                        return
                    }

                    this.voivodeship_input.innerHTML = '<option value="all">Dane ogólne</option>';
                    for (const [key, value] of Object.entries(this.data.active_work_position.kryteria.wojewodztwo.values)) {
                        var option = document.createElement("option");
                        option.text = value.name;
                        option.value = value.slug;
                        var select = this.voivodeship_input;
                        select.appendChild(option);
                    }
                    this.voivodeship_input.value = null;
                },
                addElement: function () {
                    const ref = this;
                    const filterData = this.filterData().slice(0, 6);

                    this.work_position_suggestions.innerHTML = '';

                    if (filterData.length == 0)
                        return

                    filterData.forEach((item) => {
                        const newDiv = document.createElement("a");

                        newDiv.classList.add('salary_form__suggestions-item');
                        // and give it some content
                        const newContent = document.createTextNode(item.name);

                        newDiv.addEventListener('click', () => {
                            this.work_position_input.value = item.name;
                            this.data.active_work_position = item
                            this.setVoivodeshipOptions();
                            this.generateUrl();
                            this.work_position_suggestions.innerHTML = ''
                            this.work_position_suggestions.style.display = 'none';
                        })
                        // add the text node to the newly created div
                        newDiv.appendChild(newContent);

                        // add the newly created element and its content into the DOM
                        const currentDiv = this.work_position_suggestions;
                        currentDiv.appendChild(newDiv);
                    });
                    // create a new div element
                },
                filterData: function () {
                    if (this.work_position_input.value == '' || this.work_position_input.value.length < 2)
                        return [];

                    this.work_position_suggestions.style.display = 'block';
                    const regex = new RegExp(this.work_position_input.value, 'gmi');
                    const sugested = [];


                    for (const [key, value] of Object.entries(this.data.list)) {
                        if (regex.test(value.name)) {
                            sugested.push(value);
                        }
                    }

                    return sugested;
                }
            }
            Search.init();
        })();

    }
}