// js-alphabet-button
export default function alphabetFilter() {
    var $buttons = $(".js-alphabet-button"),
        $items = $(".js-list-item");

   $($buttons).on('click', function(){
        const character = $(this).data('character');
        $buttons.removeClass('alphabet__button--active');
        $(this).addClass('alphabet__button--active');
        $items.hide();
        $('.js-list-item[data-character="'+ character +'"]').show();

   });

}