export default function tabelTabs() {
    $('.js-table-tab').on('click', function () {
        $('.js-table-tab').removeClass('monthly-settlement__tab-item--active');

        $(this).addClass('monthly-settlement__tab-item--active')

        var activeId = $(this).data('tab-id');

        $('.js-salary-table').hide();
        if (activeId === 'employer-table')
            $('.monthly-settlement__marker').addClass('monthly-settlement__marker--employer');
        else
            $('.monthly-settlement__marker').removeClass('monthly-settlement__marker--employer');
            
        $('#' + activeId).show();
    })
}