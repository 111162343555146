export default function filterCategory() {

    let checkedCategory = '.form-checkbox__checkbox';
    
    $(checkedCategory).on('click', function (e) {

        if ($(this).prop('checked')) {
            e.preventDefault();
            $('#js-list-filters-categories').removeClass('show-list');
            $(checkedCategory).removeClass('checked');
            $(this).addClass('checked');

            if(open) {
                window.location.href = $(this).val();
            }

            // let cat_id = $(this).data("cat-id");


            // $.ajax({
            //     url: ajax.url,
            //     data: {
            //         action: 'mda_filters',
            //         cat_id: cat_id
            //     },
            //     type: "post",
            //     beforeSend: function () {
            //         // $("#js-render-posts").html('');
            //     },
            //     success: function (response) {
            //         // $("#js-render-posts").html(response);
            //     }
            // });
        }
    });

    $('body').on('click', function(e) {
        if (!$(e.target).is(".js-filters-category-content")) {
            setTimeout(function() { 
            $('.js-list-filters-category-list-select').removeClass('show');
            $('.list-filters__content').removeClass('active-list');
        }, 500);
        }
    })

    $('.js-filters-category-content').on("click", function (e) {
        e.preventDefault();
        $('.list-filters__content').toggleClass('active-list');
        $('.list-filters__list--select').toggleClass('show');
    });

    $('.js-list-filters-close').on("click", function (e) {
        e.preventDefault();
        $('#js-list-filters-categories').removeClass('show-list');
        // $('.list-filters__list--select').removeClass('show');
    });

    $('.js-list-filters-mobile-open').on("click", function (e) {
        $('#js-list-filters-categories').toggleClass('show-list');
    });
} 
