export default function commentsChanges() {

    const body = document.querySelector('body');

    if ( body.classList.contains('single') ) {
        const comment = document.querySelectorAll('.comment-body');
        const section = document.querySelector('section');

        // likes do kometarzy, przeniesienie do innego diva
        comment.forEach(comment => {
            let block_likes = comment.children[1].children[1];
            let reply = comment.children[2];
            
            if (block_likes.classList.contains('cld-like-dislike-wrap') && reply) {
                reply.appendChild(block_likes);  
            }
        });

        // usunięcie "o/at" z metadaty
        const comment_metadata = document.querySelectorAll('.comment-metadata a time');
        comment_metadata.forEach(data_time => {
            let result = data_time.outerText.substring(0, 10);
            data_time.outerText = result;
        });

        // zmiana treści w paginacji po zmianie sortowania 
        if ( section.classList.contains('navigation') ) {
            let navigation_right = document.getElementsByClassName("alignright")[0].getElementsByTagName('a')[0];
            navigation_right.innerHTML="Starsze komentarze <span class='arrow-right'>»</span>";

            let navigation_left = document.getElementsByClassName("alignleft")[0].getElementsByTagName('a')[0]
            if (navigation_left) {
                    navigation_left.innerHTML = "<span class='arrow-left'>«</span> Nowsze komentarze";
            }
        }
    }
}