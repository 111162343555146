function dataHref() {
    var sectionElement = $('[data-href]');

    if (sectionElement) {
        sectionElement.on('click', function() {
            // Pobierz wartości atrybutów data
            var href = $(this).attr('data-href');
            var target = $(this).attr('data-target');
    
            // Otwórz link w nowym oknie lub karcie
            window.open(href, target);
        });
    }
}




export {
    dataHref
}