import "select2";
// import animateMenu from "./modules/animate-menu";
import pageOffset from "./modules/page-offset";
import menuMobileShow from "./modules/menu-mobile";
import linkAttribute from "./modules/link-attribute";
import showMenuFooter from "./modules/show-menu-footer";
import accordionArticle from "./modules/accordion-article";
import commentsChanges from "./modules/comments-changes";
import showSalariesContent from "./modules/show-salaries-content";


// const { default: lastArticles } = require("./functions/last-articles-fixes");
import searchForm from "./modules/search-form";
import salaryForm from "./modules/salary-form";
import alphabetList from "./modules/alphabet-list";
import filterCategory from "./modules/filters-category";
import voivodeship from "./modules/voivodeship";
import chart from "./modules/chart";
import tableTabs from "./modules/table-tabs";
import calculations from "./modules/calculations";
import compare from "./modules/compare";
import videoPlay from "./modules/video";
import { afterSlider, articleSlider, heroSlider, podcastSlider } from "./modules/swiper";
import { dataHref } from "./modules/link-redirect";
// import turn from "./modules/turn";
import pageFlipMDA from "./modules/pageFlip";
// import turnPageMDA from "./functions/turnPage";
// import zoomMDA from "./functions/zooming";
/**
 * Import modules
 */
// import { select2 } from "./modules/select2";


var documentReady = function () {
    // animateMenu();
    pageOffset();
    menuMobileShow();
    linkAttribute();
    showMenuFooter();
    accordionArticle();
    commentsChanges();
    showSalariesContent();
    searchForm();
    filterCategory();
    salaryForm();
    voivodeship();
    alphabetList();
    chart();
    tableTabs();
    calculations();
    compare();
    videoPlay();
    afterSlider();
    heroSlider();
    articleSlider();
    podcastSlider();
    dataHref();
    pageFlipMDA();



    // console.log("DOM Loaded");
    // Zmienna globalna przenosząca tłumaczenia. Konfigurowalna w config.php.
    // console.log(i18n);
    // RxJS working
    // fromEvent(document, "click").subscribe(() => {
    //     test();
    // });

    require('./modules/header-fixed');
};

if (
    document.readyState === "complete" ||
    (document.readyState !== "loading" && !document.documentElement.doScroll)
) {
    documentReady();
} else {
    document.addEventListener("DOMContentLoaded", documentReady);
}