// Select2 init
export default function voivodeship() {

    const positon_select = $("#voivodeship").select2({
        width: "100%",
        placeholder: 'Wybierz województwo',
        minimumResultsForSearch: -1,
        allowClear: true,
        "language": {
            "noResults": function(){
                $
                return "Nie znaleziono";
            }
        },
    });

    $('#voivodeship').on('select2:select', function (e) {
        $('.select2-selection__clear').empty();
      });
}
